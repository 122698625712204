<template>
    <div class="qingwu">
        <!-- 容器本身 -->
        <el-container>
            <!-- 左侧 -->
            <el-aside :width="isCollapse ? '65px' : '185px'">
                <el-scrollbar>
                    <!-- LOGO -->
                    <div class="head_logo">
                        <span>有免惠</span><font v-show="!isCollapse">总后台</font>
                    </div>
                    <!-- 左侧导航 -->
                    <el-menu
                            v-if="permisssionMenus.length"
                            background-color="#20222a"
                            text-color="#e1e1e1"
                            active-text-color="#fff"
                            :default-active="$route.path"
                            :collapse="isCollapse"
                            router
                    >
                        <template v-for="(menu, k) in permisssionMenus">
                            <sidebar-item
                                    v-if="menu.list"
                                    :key="k"
                                    :index="menu.name"
                                    :item="menu"
                            />
                        </template>
                    </el-menu>
                </el-scrollbar>
            </el-aside>
            <el-container>
                <el-header height="50px">
                    <div class="admin-header__left">
                        <div class="admin-header__item">
                            <i
                                    :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
                                    @click="isCollapse = !isCollapse"
                            ></i>
                        </div>
                        <div class="admin-header__item">
                            <i
                                    class="el-icon-refresh-right"
                                    title="刷新页面"
                                    @click="$router.go(0)"
                            ></i>
                        </div>
                    </div>
                    <div class="admin-header__right">
                        <div class="admin-header__item">
                            <i
                                    class="icon iconfont right_head_i"
                                    title="清空缓存"
                                    @click="cacheFlush"
                            >&#xe631;</i
                            >
                        </div>
                        <div class="admin-header__item">
                            <el-badge is-dot :value="12" class="item" id="dot">
                                <i class="icon iconfont right_head_i">&#xe793;</i>
                            </el-badge>
                        </div>
                        <div class="admin-header__item">
                            <el-avatar
                                    size="small"
                                    :src="userInfo.headImageUrl ||'../../../pc/zyds3.png'"
                            ></el-avatar>
                            <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  {{ userInfo.nickname
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                                <el-dropdown-menu class="head_menu" slot="dropdown">
                                    <el-dropdown-item command="resetPwd">修改密码</el-dropdown-item>
                                    <el-dropdown-item command="logout" divided>退出</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                    <!--<breadcrumb-nav></breadcrumb-nav>-->
                </el-header>
                <el-scrollbar class="main_in">
                    <el-main>
                        <transition name="el-fade-in-linear" mode="out-in">
                            <router-view></router-view>
                        </transition>
                    </el-main>
                </el-scrollbar>
            </el-container>
            <el-backtop
                    target=".main_in.el-scrollbar .el-scrollbar__wrap"
            ></el-backtop>
        </el-container>
        <el-dialog title="重置密码" :visible.sync="dialogFormVisible" width="30%">
            <el-form :model="ruleForm" label-width="100px" :rules="rules" ref="ruleForm">
                <el-form-item label="新密码" prop="password">
                    <el-input v-model="ruleForm.password" type="password" clearable show-password
                              autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPassword">
                    <el-input v-model="ruleForm.checkPassword" type="password" clearable show-password
                              autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import SidebarItem from "@/components/admin/SidebarItem.vue";
    export default {
        components: {
            SidebarItem,
        },
        props: {},
        data() {
            const validatePass = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("请输入密码"));
                } else {
                    if (this.ruleForm.checkPassword !== "") {
                        this.$refs.ruleForm.validateField("checkPassword");
                    }
                    callback();
                }
            };
            const validatePass2 = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("请再次输入密码"));
                } else if (value !== this.ruleForm.password) {
                    callback(new Error("两次输入密码不一致!"));
                } else {
                    callback();
                }
            };
            return {
                isCollapse: false, // 侧边栏缩进打开
                permisssionMenus: [],
                userInfo: {},
                dialogFormVisible: false,
                ruleForm: {
                    password: '',
                    checkPassword: ''
                },
                rules: {
                    password: [
                        {validator: validatePass, trigger: "blur"},
                        {
                            min: 6,
                            max: 20,
                            message: "长度在 6 到 20 个字符",
                            trigger: "blur",
                        },
                    ],
                    checkPassword: [
                        {validator: validatePass2, trigger: "blur"},
                        {
                            min: 6,
                            max: 20,
                            message: "长度在 6 到 20 个字符",
                            trigger: "blur",
                        },
                    ],
                },
            };
        },
        methods: {
            handleCommand(e) {
                // 点击退出
                if (e === "logout") {
                    this.logout();
                }
                if (e === 'resetPwd') {
                    this.dialogFormVisible = true
                }
            },
            getUserInfo() {
                // this.$get(this.$api.getUserInfo).then((res)=>{
                //     this.userInfo = res.data;
                // });
                this.userInfo = JSON.parse(localStorage.getItem("user_info") || {});
            },
            logout() {
                // this.$get(this.$api.logout).then(() => {
                localStorage.removeItem("token");
                this.$router.push("/Admin/login");
                // });
            },
            cacheFlush() {
                this.$get(this.$api.cacheFlush).then(() => {
                    this.$message.success("清空完成");
                });
            },
            getPermissionMenus() {
                //获取菜单时需要指定对应系统的菜单
                this.$post(this.$api.getPermissionMenus, {"moduleId": "494477410638356480"}).then((res) => {
                    this.permisssionMenus = res.data;
                });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$post(this.$api.adminReSetPwd, {'password': this.ruleForm.password}).then((res) => {
                                if (res.code === 1) {
                                    this.$message({
                                        type: "success",
                                        message: "重置密码成功!",
                                    });
                                    this.dialogFormVisible = false;
                                    this.$refs[formName].resetFields();
                                } else {
                                    this.$message({
                                        type: "error",
                                        message: res.msg,
                                    });
                                }
                            }
                        );
                    } else {
                        return false;
                    }
                });
            },
        },
        created() {
            this.getPermissionMenus();
            this.getUserInfo();
        },
    };
</script>
<style lang="scss" scoped>
    .main_in {
        background: #f3f3f4;
        width: 100%;
        box-sizing: border-box;

        .el-scrollbar__wrap {
            overflow-x: hidden !important;
        }
    }

    .el-menu {
        border-right: none;

        .el-submenu__title i {
            color: #e1e1e1;
        }

        .title_i {
            color: #cfcfcf;
            padding-right: 15px;
        }
    }

    .el-scrollbar {
        height: 100%;
    }

    .el-aside {
        height: 100%;
        background: #20222a;
        overflow: hidden !important;
    }

    .el-container {
        height: 100%;
    }

    .el-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e7eaec !important;
    }

    $admin-header: "admin-header";
    .#{$admin-header} {
        $item-gutter: 20px;

        &__left,
        &__right {
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #333;
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        &__left {
            .#{$admin-header}__item {
                &:not(:last-child) {
                    padding-right: $item-gutter;
                }
            }
        }

        &__right {
            .#{$admin-header}__item {
                &:not(:first-child) {
                    padding-left: $item-gutter;
                }
            }
        }
    }

    .head_logo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        font-size: 20px;
        background: #000;
        color: #fff;

        span {
            color: #409eff;
        }
    }

    /deep/ .el-menu-item.is-active {
        background-color: rgb(26, 27, 34) !important;
    }
</style>
