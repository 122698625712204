<template functional>
  <el-submenu v-if="props.item.list.length" :index="props.item.title">
    <template #title>
      <i :class="props.item.icon"></i>
      <span slot="title">{{ props.item.title }}</span>
    </template>

    <template v-for="(child, index) in props.item.list">
      <sidebar-item
        v-if="child.list && child.list.length > 0"
        :item="child"
        :key="index"
      />
      <el-menu-item v-else :key="index" :index="child.jump">
        <!--<i class="el-icon-location"></i>-->
        {{ child.title }}
      </el-menu-item>
    </template>
  </el-submenu>
  <el-menu-item v-else :index="props.item.title">
    <i :class="props.item.icon"></i>
    {{ props.item.title }}
  </el-menu-item>
</template>

<script>
export default {
  name: "SidebarItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
